import axios from './../../request/axios.js'
import api from './../../request/api.js'

const teacherService = {
  // AdministratorPartnerList: function (params) {
  //   return axios.post(api.management.Administrator.partnerList, params)
  // }
  // 获取省份
  AdministratorUserprovince: function (params) {
    return axios.post(api.management.Administrator.userprovince, params)
  },
  // 获取市级
  AdministratorUserCity: function (params) {
    return axios.post(api.management.Administrator.userCity, params)
  },
  // 获取县级
  AdministratorUserArea: function (params) {
    return axios.post(api.management.Administrator.userArea, params)
  },
  // 机构
  AdministratorGetHospital: function (params) {
    return axios.post(api.management.Administrator.getHospital, params)
  },
  AdministratorDelHospital: function (params) {
    return axios.post(api.management.Administrator.delHospital, params)
  },
  AdministratorAddHospital: function (params) {
    return axios.post(api.management.Administrator.addHospital, params)
  },
  AdministratorUpdateHospital: function (params) {
    return axios.post(api.management.Administrator.updateHospital, params)
  },
  // 政府
  AdministratorGovernment: function (params) {
    return axios.post(api.management.Administrator.government, params)
  },
  // 学校
  AdministratorFindSchoolList: function (params) {
    return axios.post(api.management.Administrator.findSchoolList, params)
  },
  AdministratorDeleteSchool: function (params) {
    return axios.post(api.management.Administrator.deleteSchool, params)
  },
  // 班级
  AdministratorPartnerClassList: function (params) {
    return axios.post(api.management.Administrator.partnerClassList, params)
  },
  AdministratorDelNewGradeClass: function (params) {
    return axios.post(api.management.Administrator.delNewGradeClass, params)
  },
  AdministratorGetGradeList: function (params) {
    return axios.post(api.management.Administrator.getGradeList, params)
  },
  // 教师
  AdministratorGetTeacherList: function (params) {
    return axios.post(api.management.Administrator.getTeacherList, params)
  },
  // 教师编辑
  AdministratorEditTeacher: function (params) {
    return axios.post(api.management.Administrator.editTeacher, params)
  },
  // 教师添加
  AdministratorAddTeacher: function (params) {
    return axios.post(api.management.Administrator.addTeacher, params)
  },
  // 教师删除
  AdministratorDeleteTeacher: function (params) {
    return axios.post(api.management.Administrator.deleteTeacher, params)
  },
  // 添加学校
  AdministratorAddSchool: function (params) {
    return axios.post(api.management.Administrator.addSchool, params)
  },
  // 编辑学校
  AdministratorUpdSchool: function (params) {
    return axios.post(api.management.Administrator.updSchool, params)
  },
  // 添加年级
  AdministratorAddNewGrade: function (params) {
    return axios.post(api.management.Administrator.addNewGrade, params)
  },
  // 获取年级
  AdministratorGetNewGrade: function (params) {
    return axios.post(api.management.Administrator.getNewGrade, params)
  },
  // 删除年级
  AdministratorDelNewGrade: function (params) {
    return axios.post(api.management.Administrator.delNewGrade, params)
  },
  // 编辑年级
  AdministratorEditNewGrade: function (params) {
    return axios.post(api.management.Administrator.editNewGrade, params)
  },
  // 获取班级
  AdministratorListSchoolClass: function (params) {
    return axios.post(api.management.Administrator.listSchoolClass, params)
  },
  // 添加班级
  AdministratoraddNewGradeClass: function (params) {
    return axios.post(api.management.Administrator.addNewGradeClass, params)
  },
  // 班级获取绑定教师
  AdministratorSearchClassTeacher: function (params) {
    return axios.post(api.management.Administrator.searchClassTeacher, params)
  },
  AdministratoreditNewGradeClass: function (params) {
    return axios.post(api.management.Administrator.editNewGradeClass, params)
  },
  // 获取政府
  AdministratorGetGovernmentList: function (params) {
    return axios.post(api.management.Administrator.getGovernmentList, params)
  },
  // 添加政府
  AdministratorAddGovernment: function (params) {
    return axios.post(api.management.Administrator.addGovernment, params)
  },
  // 编辑政府
  AdministratorEditGovernment: function (params) {
    return axios.post(api.management.Administrator.editGovernment, params)
  },
  // 删除政府
  AdministratorDelGovernment: function (params) {
    return axios.post(api.management.Administrator.delGovernment, params)
  },
  // 档案管理全部获取
  AdministratorGetAllChildren: function (params) {
    return axios.post(api.management.Administrator.getAllChildren, params)
  },
  // 搜索档案
  AdministratorSearchChildren: function (params) {
    return axios.post(api.management.Administrator.searchChildren, params)
  },
  // 消息中心
  AdministratorGetSchoolMessage: function (params) {
    return axios.post(api.management.Administrator.getSchoolMessage, params)
  }
}
export default teacherService
