<template>

<!-- 学校管理页面 新增-->
  <div class="container">
    <div class="body-container">
      <div class="body-section">
        <div class="all-container">
          <div class="all-section">
            <div class="government-container">
              <div class="government-from_section">
                <el-form label-position="right" label-width="100px" :model="basicInfo" ref="basicInfoSRef" :rules="basicInfoSRules">
                   <el-form-item label="选择省市区" prop="codeData">
                    <el-cascader v-model="basicInfo.codeData" :props="props"></el-cascader>
                  </el-form-item>
                  <el-form-item label="学校名称" prop="schoolName">
                    <el-input maxlength="11" v-model="basicInfo.schoolName"></el-input>
                  </el-form-item>
                  <el-form-item label="学校级别" prop="schoolType">
                    <el-select v-model="basicInfo.schoolType" placeholder="请选择">
                      <el-option
                        v-for="item in level"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="学校性质" prop="schoolNature">
                    <el-radio-group v-model="basicInfo.schoolNature" >
                      <el-radio :label="0">公办</el-radio>
                      <el-radio :label="1">民办</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="学校地址" prop="address">
                    <el-input  maxlength="50" v-model="basicInfo.address"></el-input>
                  </el-form-item>
                  <el-form-item label="联系人" prop="principalName">
                    <el-input  maxlength="11" v-model="basicInfo.principalName"></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话" prop="principalPhone">
                    <el-input maxlength="11" v-model="basicInfo.principalPhone"></el-input>
                  </el-form-item>
                </el-form>
                <div class="button-section">
                  <el-button class="add-button" @click="addSchoolData">提交</el-button>
                  <router-link :to="{name: 'managementSchool'}">
                    <el-button class="return-button">取消</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdministratorService from '@/globals/service/management/Administrator.js'

export default {
  data () {
    return {
      level: [
        { value: 0, label: '幼儿园' },
        { value: 1, label: '小学' },
        { value: 2, label: '初中' },
        { value: 3, label: '高中' },
        { value: 4, label: '大学' },
        { value: 5, label: '幼儿园-小学' },
        { value: 6, label: '幼儿园-初中' },
        { value: 7, label: '幼儿园-高中' },
        { value: 8, label: '小学-初中' },
        { value: 9, label: '小学-高中' },
        { value: 10, label: '初中-高中' }
      ],
      props: {
        lazy: true,
        lazyLoad (node, resolve) {
          const { level } = node
          let data = []
          setTimeout(() => {
            if (level === 1) {
              AdministratorService.AdministratorUserCity({ provinceId: node.value }).then(res => {
                data = res.data.map(item => {
                  return {
                    value: item.citycode,
                    label: item.city,
                    leaf: level >= 2
                  }
                })
                resolve(data)
              })
              return
            }
            if (level === 2) {
              AdministratorService.AdministratorUserArea({ cityId: node.value }).then(res => {
                data = res.data.map(item => {
                  return {
                    value: item.areacode,
                    label: item.area,
                    leaf: level >= 2
                  }
                })
                resolve(data)
              })
              return
            }
            AdministratorService.AdministratorUserprovince().then(res => {
              data = res.data.map(item => {
                return {
                  value: item.provincecode,
                  label: item.province,
                  leaf: level >= 2
                }
              })
              resolve(data)
            })
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          }, 1000)
        }
      },
      basicInfoSRules: {
        codeData: [
          { required: true, message: '请选择学校地址', trigger: 'change' }
        ],
        // 学校名称
        schoolName: [
          { required: true, message: '请输入学校名称', trigger: 'change' }
        ],
        // 学校详细地址
        address: [
          { required: true, message: '请输入学校详细地址', trigger: 'change' }
        ],
        // 学校等级
        schoolType: [
          { required: true, message: '请选择学校等级', trigger: 'change' }
        ],
        // 学校性质
        schoolNature: [
          { required: true, message: '请选择学校性质', trigger: 'change' }
        ],
        // 联系人名称
        principalName: [
          { required: true, message: '请输入联系人名称', trigger: 'change' }
        ],
        // 联系方式
        principalPhone: [
          { required: true, message: '请输入联系方式', trigger: 'change' },
          { required: true, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ]
      },
      basicInfo: {
        // phone: '',
        // name: '',
        // role: null
        codeData: [],
        // 省份id
        provincecode: null,
        // 市级id
        citycode: null,
        // 县级Id
        areacode: null,
        // 学校名称
        schoolName: null,
        // 学校详细地址
        address: null,
        // 学校等级
        schoolType: null,
        // 学校性质
        schoolNature: null,
        // 联系人名称
        principalName: null,
        // 联系方式
        principalPhone: null
      }
    }
  },
  created () {
    // this.getData()/
  },
  methods: {
    addSchoolData () {
      this.$refs.basicInfoSRef.validate(valid => {
        if (valid) {
          const data = this.basicInfo
          data.provinceId = data.codeData[0]
          data.cityId = data.codeData[1]
          data.areaId = data.codeData[2]

          AdministratorService.AdministratorAddSchool(data).then(res => {
            this.$alert(`账号: ${res.data.phone}; 密码: ${res.data.password}`, '请记住你的账号密码', {
              confirmButtonText: '复制账号密码',
              callback: () => {
                var input = document.createElement('input') // 直接构建input
                input.value = '账号：' + res.data.phone + ' 密码：' + res.data.password // 设置内容
                document.body.appendChild(input) // 添加临时实例
                input.select() // 选择实例内容
                document.execCommand('Copy') // 执行复制
                document.body.removeChild(input) // 删除临时实例
                this.$router.push({ name: 'managementSchool' })
              }
            })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  min-height: 100%;
  flex: 1;
  background-color: #fff;
  color: #333;
  .body-container{
    padding: 80px 40px 20px 61px;
    .body-section{
      min-width: 450px;
    }
    .basic-container{
      max-width: 450px;
      .el-select{
        width: 100%;
      }
    }
    .all-container{
      .all-section-header{
          width: 100%;
          min-width: 160px;
          height: 50px;
          background-color: #F8F8F8;
          padding:  0 30px;
          border-radius: 6px;
          margin-bottom: 40px;
        .header-title{
          line-height: 50px;
          font-size: 18px;
          color: #333;
        }
      }
      .organization-from_section{
        max-width: 450px;
        /deep/.el-icon-camera{
          font-size: 50px;
          color: #34BBEC;
          margin-top: 50px;

        }
        /deep/.el-upload-dragger{
          width: 350px;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .government-from_section{
        max-width: 450px;
        .el-select{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .school-from_section{
        max-width: 450px;
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .class-from_section{
        max-width: 450px;
        .el-select, .el-autocomplete{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
    }
  }
}
/deep/.el-popper, .el-select-dropdown {
  .hover{
    background-color: #34BBEC !important;
    color: #fff !important;
  }
}
</style>
